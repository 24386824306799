import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function ClickerFrame() {
    const { networkName } = useParams();
    const member_id = localStorage.getItem('member_id');
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect if member_id is not found or undefined
        if (!member_id) {
            navigate('/webclicker');
        }
    }, [member_id, navigate]);


    let framebody = '';
    switch (networkName.toLowerCase()) {
        case "adgatemedia":
            framebody = `<iframe src="https://wall.adgaterewards.com/nauZp20/${member_id}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"> </iframe>`;
            break;
        case "lootably":
            framebody = `<iframe src="https://wall.lootably.com/?placementID=clr5agw0q098001076s8x1qzx&sid=${member_id}" style="width: 100%; height: 90%; border: none; border-radius: 10px;"> </iframe>`;
            break;
        case 'mmwall':
                framebody = `<iframe src="https://wall.make-money.top/?p=644&u=${member_id}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
                break;    
        case "notik":
            framebody = `<iframe src="https://notik.me/coins?api_key=6NhoZqnXhqgzKQsR2yOIa6xjkQkBImJ7&pub_id=l6EiBj&app_id=3pybvztSsp&user_id=${member_id}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"> </iframe>`;
            break;
        case "cpalead":
            framebody = `<iframe src="https://fastsvr.com/list/517353&subid=${member_id}"></iframe>`;
            break;
        case 'revlum':
            framebody = `<iframe src="https://revlum.com/offerwall/o6zbt7tfmekrjlqqsfqw8xy8z0q0k6/${member_id}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
            break;
        case 'torox':
                framebody = `<iframe src="https://torox.io/ifr/show/30469/${member_id}/15593/" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
            break;
        case 'adbreak':
                framebody = `<iframe src="https://wall.adbreakmedia.com/appid/${member_id}" style="width: 100%; height: 100%; border: none; border-radius: 10px;"></iframe>`;
            break;
    }

    return (
        <div className="fullframe">
            <div className="clickerframe" id="clickerwall" dangerouslySetInnerHTML={{ __html: framebody }} />
        </div>
    );
}
export default ClickerFrame;